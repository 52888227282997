import { Show, For, Switch, Match } from 'solid-js';

export function HiddenFields(props: { data?: Record<string, unknown> }) {
	return (
		<Show when={props.data}>
			{(data) => (
				<For each={Object.entries(data())}>
					{([key, val]) => (
						<Switch>
							<Match when={typeof val === 'string' || typeof val === 'number'}>
								<input type="hidden" name={key} value={val as string} />
							</Match>
							<Match when={typeof val === 'object' && val}>
								{(val) => (
									<HiddenFields
										data={Object.entries(val()).reduce(
											(memo, [subKey, val]) => {
												memo[`${key}.${subKey}`] = val;
												return memo;
											},
											{} as Record<string, unknown>,
										)}
									/>
								)}
							</Match>
						</Switch>
					)}
				</For>
			)}
		</Show>
	);
}
