import { Icon } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Show } from 'solid-js';
import type { JSXElement, ParentProps } from 'solid-js';

type Props = ParentProps<{
	action?: JSXElement;
	step: number;
	state: 'completed' | 'current' | 'waiting';
}>;

export function SectionStep(props: Props) {
	return (
		<div class="flex flex-row items-center gap-4">
			<div
				role="presentation"
				class={twJoin(
					'flex size-10 shrink items-center justify-center rounded-full text-xl font-semibold',
					props.state === 'completed' && 'bg-brand text-brand-100',
					props.state === 'current' && 'bg-brand-100 text-brand',
					props.state === 'waiting' && 'bg-neutral-300 text-neutral-700',
				)}
			>
				<Show when={props.state !== 'completed'} fallback={<Icon name="check" />}>
					{props.step.toString()}
				</Show>
			</div>
			<h2 class="grow text-xl font-semibold leading-10">{props.children}</h2>
			{props.action}
		</div>
	);
}
